<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <WelcomeCard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <h5 style="color: red">
            เปิดจองเสื้อ RUNNING #1 วันนี้ - 05 พ.ย. 2567 โดยจะมีสองสีให้เลือก
            สีขาว และสีฟ้า เนื้อผ้าไมโครเรียบ ราคา 220 บาทรวมส่ง
          </h5>
        </div>
        <div class="row">
          <img src="@/assets/images/running/running-white.jpg" />
          <img
            src="@/assets/images/running/running-light-blue.jpg"
            alt="line-group"
          />
        </div>
        <hr />
        <div v-if="this.tshirt_order_obj === null">
        <div class="d-flex justify-content-center">
          <h5 style="color: red">
            เปิดจองเสื้อ RUNNING #1 วันนี้ - 05 พ.ย. 2567
          </h5>
          <br />
        </div>
        <div class="row">
          <div class="col-sm-4 col-lg-6 col-xl-6">
            <div class="form-floating mb-3">
              <select
                class="form-select"
                required
                v-model="model"
                id="floatingSelect"
                aria-label="Floating label select example"
              >
                <option value="สีขาวSSSรอบอก34">สีขาว SSS รอบอก 34"</option>
                <option value="สีขาวSSรอบอก36">สีขาว SS รอบอก 36"</option>
                <option value="สีขาวSรอบอก38">สีขาว S รอบอก 38"</option>
                <option value="สีขาวMรอบอก40">สีขาว M รอบอก 40"</option>
                <option value="สีขาวLรอบอก42">สีขาว L รอบอก 42"</option>
                <option value="สีขาวXLรอบอก44">สีขาว XL รอบอก 44"</option>
                <option value="สีขาว2XLรอบอก46">สีขาว 2XL รอบอก 46"</option>
                <option value="สีขาว3XLรอบอก48">สีขาว 3XL รอบอก 48"</option>
                <option value="สีขาว4XLรอบอก50">สีขาว 4XL รอบอก 50"</option>
                <option value="สีขาว5XLรอบอก52">สีขาว 5XL รอบอก 52"</option>
                <option value="สีขาว6XLรอบอก54">สีขาว 6XL รอบอก 54"</option>
                <option value="สีฟ้าSSSรอบอก34">สีฟ้า SSS รอบอก 34"</option>
                <option value="สีฟ้าSSรอบอก36">สีฟ้า SS รอบอก 36"</option>
                <option value="สีฟ้าSรอบอก38">สีฟ้า S รอบอก 38"</option>
                <option value="สีฟ้าMรอบอก40">สีฟ้า M รอบอก 40"</option>
                <option value="สีฟ้าLรอบอก42">สีฟ้า L รอบอก 42"</option>
                <option value="สีฟ้าXLรอบอก44">สีฟ้า XL รอบอก 44"</option>
                <option value="สีฟ้า2XLรอบอก46">สีฟ้า 2XL รอบอก 46"</option>
                <option value="สีฟ้า3XLรอบอก48">สีฟ้า 3XL รอบอก 48"</option>
                <option value="สีฟ้า4XLรอบอก50">สีฟ้า 4XL รอบอก 50"</option>
                <option value="สีฟ้า5XLรอบอก52">สีฟ้า 5XL รอบอก 52"</option>
                <option value="สีฟ้า6XLรอบอก54">สีฟ้า 6XL รอบอก 54"</option>
              </select>
              <label for="floatingSelect">ไซส์ เสื้อ</label>
            </div>
          </div>
          <!-- <div class="col-sm-6 col-lg-6 col-xl-6">
            <div class="form-floating mb-3">
              <select
                class="form-select"
                required
                v-model="unit"
                id="floatingSelect"
                aria-label="Floating label select example"
              >
                <option value="1" selected>1</option>
              </select>
              <label for="floatingSelect">จำนวน</label>
            </div>
          </div> -->
        </div>
        
        <div class="row">
          <div class="d-flex justify-content-center">
            <button
              type="button"
              @click.prevent="add_tshirt"
              style="cursor: pointer"
              class="btn btn-primary btn-lg"
            >
              <vue-feather type="plus-circle" size="16"></vue-feather> เพิ่ม
            </button>
            &nbsp;<button
              type="button"
              @click.prevent="delete_tshirt"
              style="cursor: pointer"
              class="btn btn-primary btn-lg"
            >
              <vue-feather type="delete" size="16"></vue-feather> ลบ
            </button>
          </div>
        </div>

        <hr />

        
        <div class="d-flex justify-content-center">
          <h5>
            ข้อมูลการสั่ง จองเสื้อ RUNNING#1
          </h5>
        </div>
        <p>
          <span
            style="padding: 5px; margin: 3px; font-size: x-large"
            v-for="(item, index) in this.tshirt_order"
            :key="index"
            class="btn btn-outline-success"
            ><i class="fa fa-check-circle-o" aria-hidden="true"></i
            >&nbsp;<b> {{ item.model }} จำนวน {{ item.unit }} ตัว</b>
          </span>
        </p>

        <form   @submit.prevent="create_order_tshirt" class="needs-validation">
          <div class="d-flex justify-content-center" v-if=" this.tshirt_order.length > 0">
          <h5>สั่งเสื้อจำนวน {{ this.tshirt_order.length }} ตัว</h5>
        </div>
        <div class="d-flex justify-content-center">
          <h5>ยอดโอน {{ this.tshirt_order.length*220 }} THB</h5>
        </div>
        <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" disabled value="6603525615"
                      id="floatingInput" />
                    <label for="floatingInput">หมายเลขบัญชี</label>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" disabled value="ณัฐภรณ์ ชูพยัคฆ์"
                      id="floatingInput" />
                    <label for="floatingInput">ชื่อบัญชี</label>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" disabled value="กรุงไทย"
                      id="floatingInput" />
                    <label for="floatingInput">ธนาคาร</label>
                  </div>
                </div>
              </div>
          <div class="d-flex justify-content-center">
                      <div class="form-floating mb-3">
                        <input type="file" id="fileSlip"  accept="image/png, image/jpeg" required class="form-control" />
                        <label for="fileSlip">เลือกรูปสลิป</label>
                      </div>
        </div>
        <div class="d-flex justify-content-center">
                      <div class="form-floating mb-1">
                        <input placeholder="06:00" maxlength="5" minlength="5" type="text" v-model="payment_slip_time" id="payment_slip_time" required class="form-control" />
                        <label for="image_slip_time">เวลาโอนเงิน HH:mm</label>
                      </div>
        </div>
        <div class="row">
          <b>ที่อยู่สำหรับการจัดส่ง</b>

          <div class="row">
            <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_name" class="form-control" id="floatingInput">
                      <label for="floatingInput">ชื่อ-สกุล ผู้รับ</label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required autocomplete="off" inputmode="numeric" minlength="5" maxlength="15" v-model="delivery_add_mobile_no" class="form-control"
                        id="floatingInput">
                      <label for="floatingInput">หมายเลขโทรศัพท์</label>
                    </div>
                  </div>
            <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_no" minlength="1" maxlength="50" class="form-control" id="floatingInput">
                      <label for="floatingInput">บ้านเลขที่/ซอย/ ถนน</label>
                    </div>
            </div>
            
            <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_sub_district" minlength="3" maxlength="50" class="form-control" id="floatingInput">
                      <label for="floatingInput">ตำบล/แขวง</label>
                    </div>
            </div>


             <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_district" minlength="3" maxlength="50" class="form-control" id="floatingInput">
                      <label for="floatingInput">อำเภอ/เขต</label>
                    </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="text" required v-model="delivery_add_province" minlength="3" maxlength="50" class="form-control" id="floatingInput">
                      <label for="floatingInput">จังหวัด</label>
                    </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-4">
                    <div class="form-floating mb-3">
                      <input type="number" required v-model="delivery_add_postcode" autocomplete="off" inputmode="numeric"
                        class="form-control" minlength="5" maxlength="5" id="floatingInput">
                      <label for="floatingInput">รหัสไปรษณีย์</label>
                    </div>
                  </div>
          </div>
        </div>

        <div class="col-sm-12">
                      <div class="d-flex justify-content-center">
                        <button type="submit" style="cursor: pointer" class="btn btn-primary btn-lg">
                          [ <vue-feather type="save" size="18"></vue-feather> บันทึกข้อมูล การจอง]
                        </button>
                        </div>
        </div>
      </form>
      </div>

        <p>&nbsp;</p>
      </div>
      <div v-if="this.tshirt_order_obj !== null">
        <div style="padding: 30px;" class="d-flex justify-content-center">
          <h3 style="color: green;"><vue-feather stroke="green" type="check-circle" size="30"> </vue-feather> คุณสั่งจองเสื้อเรียบร้อยแล้วจำนวน {{ this.tshirt_order_obj.unit }} ตัว <br>เมื่อ {{ this.tshirt_order_obj.create_date_txt }} <br>หากจัดส่งเรียบร้อยแล้ว จะแจ้งอีกครั้ง</h3>

        </div>
        <div class="d-flex justify-content-center">
           <img style="width: 300px;" :src="this.tshirt_order_obj.payment_slip_url_txt" alt="image slip" class="img-thumbnail" />
        </div>
        <div class="d-flex justify-content-center">
                      <h5 >เวลาที่โอนเงิน: {{ this.tshirt_order_obj.payment_slip_time }}</h5>
                    </div>
        <div class="d-flex justify-content-center">
               <h5 >ยอดเงิน: {{ this.tshirt_order_obj.amt }} THB</h5>
        </div>
        <div style="padding-left: 20px;">
          <span
            style="padding: 5px; margin: 3px; font-size: larger"
            v-for="(item, index) in this.tshirt_order_obj_json_result"
            :key="index"
            class="btn btn-outline-dark"><i></i>&nbsp;<b> {{ item.model }} จำนวน {{ item.unit }} ตัว</b>
          </span>
          <br>
          <b>จัดส่งไปยัง : {{ this.tshirt_order_obj.delivery_add_name }} {{ this.tshirt_order_obj.delivery_add_mobile_no }} {{ this.tshirt_order_obj.delivery_add_no }}
          {{ this.tshirt_order_obj.delivery_add_sub_district }} {{ this.tshirt_order_obj.delivery_add_district }} {{ this.tshirt_order_obj.delivery_add_province }} {{ this.tshirt_order_obj.delivery_add_postcode }}</b>
        </div>
        <p>&nbsp;</p>
        <hr>
        <div style="padding: 30px;" class="d-flex justify-content-left">
          <b>* หากสั่งจองแล้วต้องการแก้ไขข้อมูล หรือสอบถามข้อมูลเพิ่มเติมได้ที่ Page <a href="https://www.facebook.com/running.in.th" target="_blank">https://www.facebook.com/running.in.th</a></b>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeCard from "./WelcomeCard.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";
import axios from "axios";
export default {
  components: {
    WelcomeCard,
  },
  data() {
    return {
      model: "สีขาวSSSรอบอก34",
      unit: 1,
      payment_slip_url: "",
      payment_slip_time: "",
      unit_price: 220,
      amt: 0,
      tshirt_order: [],
      delivery_add_name: null,
      delivery_add_mobile_no: null,
      delivery_add_no: null,
      delivery_add_sub_district: null,
      delivery_add_district: null,
      delivery_add_province: null,
      delivery_add_postcode: null,

      tshirt_order_obj: {},
      tshirt_order_obj_json_result: {},
      token: localStorage.getItem("jwt")
    };
  },
  async mounted() {
    if(localStorage.getItem("_tshirt_order") !== null){
      this.tshirt_order = JSON.parse(localStorage.getItem("_tshirt_order"));
    }
    
    this.get_tshirt_order();
  },
  async created(){
    this.token = localStorage.getItem("jwt")
  },
  methods: {
    async get_tshirt_order(){
      let data = {
        a:"d"
      }
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.get("v1/admin/tshirt-order",data,{
        headers
        }
      );
      if (response.data.status === "success") {
        this.tshirt_order_obj =response.data.body
        if(this.tshirt_order_obj !== null){
          this.tshirt_order_obj_json_result = JSON.parse(this.tshirt_order_obj.tshirt_order_json)
        }
        
      }else{

      }
        
    },
    async create_order_tshirt(){

      if(this.tshirt_order.length <=0 ){
        alert("กรุณา เลือกขนาดเสื้อและสีหน่อยนะครับ ");
        return false;
      }else{
        if(confirm("การจองจะบันทึกข้อมูลได้เพียงครั้งเดียว กรุณา  ตรวจสอบความถูกต้องก่อนกดยืนยัน  คุณต้องการบันทึกข้อมูลการจองหรือไม่")){
        let data = {
        tshirt_order: this.tshirt_order,
        payment_slip_time: this.payment_slip_time,
        delivery_add_name: this.delivery_add_name,
        delivery_add_mobile_no: this.delivery_add_mobile_no,
        delivery_add_no: this.delivery_add_no,
        delivery_add_sub_district: this.delivery_add_sub_district,
        delivery_add_district: this.delivery_add_district,
        delivery_add_province: this.delivery_add_province,
        delivery_add_postcode: this.delivery_add_postcode,
        amt: this.unit_price*this.tshirt_order.length
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/tshirt-order",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        
        // call upload
        console.log("response:"+JSON.stringify(response))
        var formdata = new FormData();
        formdata.append("file", fileSlip.files[0], fileSlip.files[0].name);
        formdata.append("uuid", response.data.body.uuid);
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          process.env.VUE_APP_BACKEND_API + "/v1/upload/tshirt-order-file",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);
           
            alert("บันทึกข้อมูลเรียบร้อย")
            this.get_tshirt_order()
            
          })
          .catch((error) => console.log("error", error));

      } else {
        alert("create_order_tshirt พบข้อผิดพลาด!");
      }
      }
      }
      
      
    },
    async add_tshirt() {
      if(this.model !== null){
        let data = {
        model: this.model,
        unit: this.unit,
      };

      console.log("data push:"+JSON.stringify(data))
      this.tshirt_order.push(data);
      localStorage.setItem("_tshirt_order", JSON.stringify(this.tshirt_order));

      Swal.fire({
        position: "top-center",
        icon: "success",
        title: "เพิ่มข้อมูลเรียบร้อย",
        showConfirmButton: false,
        timer: 1500,
      });
      }
      
    },

    async delete_tshirt() {
      this.tshirt_order = [];
      localStorage.removeItem("_tshirt_order");
    },
  },
};
</script>
