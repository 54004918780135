import { createWebHistory, createRouter } from "vue-router";
import Body from '../components/body';

// user
import userProfile from '../pages/users/profile/userProfile.vue'
import userCards from '../pages/users/cards/userCards.vue'
import userEdit from '../pages/users/edit/userEdit.vue'

// uikits
import alert from '../pages/uikits/alert';
import Helperclasses from '../pages/uikits/helper_classes';
import Avatars from '../pages/uikits/avatars';
import Grid from '../pages/uikits/grid';
import Tagpills from '../pages/uikits/tag_pills';
import Typography from '../pages/uikits/typography';
import Progressbar from '../pages/uikits/progress_bar';
import Modal from '../pages/uikits/modal';
import Popover from '../pages/uikits/popover';
import Tooltip from '../pages/uikits/tooltip';
import Spinners from '../pages/uikits/loader';
import Dropdown from '../pages/uikits/dropdown';
import Accordion from '../pages/uikits/accordion';
import Boxshadow from '../pages/uikits/box_shadow';
import Lists from '../pages/uikits/lists';
import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";

// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

/* Authentication */
import LoginOne from '../pages/authentication/login_one';
import LoginTwo from '../pages/authentication/login_two';
import LoginValidate from '../pages/authentication/login_validation.vue';
import LoginTooltip from '../pages/authentication/login_tooltip.vue'
import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

import RegisterImage from '../pages/authentication/register_image';
import RegisterImage2 from '../pages/authentication/register_image2';
import Unlock from '../pages/authentication/unlock.vue';
import ForgetPassword from '../pages/authentication/forget_password';
import ResetPassword from '../pages/authentication/reset_password';
import Maintenance from '../pages/authentication/maintenance.vue'
/* Auth */
import login from '../auth/login.vue';
import Register from '../auth/register.vue';
import Forgot_Password from '../auth/forgotPassword.vue';
// comingsoon
import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';

import upload from '../pages/upload/upload.vue'
import upload_images from '../pages/upload/upload_images.vue'
import order from '../pages/running/order.vue'
import order_mhao from '../pages/running/order_mhao.vue'
import order_mhao_view from '../pages/running/order_mhao_view.vue'
import order_hybrid_wholesale_view from '../pages/running/order_hybrid_wholesale_view.vue'
import order_admin from '../pages/running/order_admin.vue'
import event_adjustment from '../pages/running/event_adjustment.vue'
import event_adjustment_admin from '../pages/running/event_adjustment_admin.vue'
import event_create from '../pages/running/event_create.vue'
import system_dashboard from '../pages/running/system_dashboard.vue'
import administrator_dashboard from '../pages/running/administrator_dashboard.vue'


import home from '../pages/running/home.vue'
import tshirt from '../pages/running/tshirtOrder.vue'
import participate from '../pages/running/participate.vue'
import partner from '../pages/running/partner.vue'
import wallet from '../pages/running/wallet.vue'
import withdrawalHistory from '../pages/running/withdrawalHistory.vue'



const routes = [
  {
    path: '/',
    component: Body,

    children: [
      {
        path: '',
        name: 'home',
        component: home,
        meta: {
          title: 'Photo Management - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: '/tshirt',
        name: 'tshirt',
        component: tshirt,
        meta: {
          title: 'ISHOOTRUN.IN.TH ค้นหาใบหน้า ด้วยรอยยิ้ม ',
        }
      },
      {
        path: '/participate',
        name: 'participate',
        component: participate,
        meta: {
          title: 'กิจกรรมที่เข้าร่วม - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: '/partner',
        name: 'partner',
        component: partner,
        meta: {
          title: 'กิจกรรมที่ดูแล - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'upload/:q',
        name: 'upload',
        component: upload,
        meta: {
          title: 'UPLOAD - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'upload-images/:q',
        name: 'upload-images',
        component: upload_images,
        meta: {
          title: 'รูปที่ upload สำเร็จ - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'order/:q',
        name: 'order',
        component: order,
        meta: {
          title: 'ORDER - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'orders/:q',
        name: 'order_mhao',
        component: order_mhao,
        meta: {
          title: 'รายการขาย - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'orders/:q/view/:confirm_key',
        name: 'order_mhao_view',
        component: order_mhao_view,
        meta: {
          title: 'รูปที่ขาย - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'order/:q/view/:confirm_key',
        name: 'order_hybrid_wholesale_view',
        component: order_hybrid_wholesale_view,
        meta: {
          title: 'รูปที่ขาย - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'order-admin/:q',
        name: 'order_admin',
        component: order_admin,
        meta: {
          title: 'order admin - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'event-adjustment/:q',
        name: 'event_adjustment',
        component: event_adjustment,
        meta: {
          title: 'RUNNING ADJUSTMENT ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที - ISHOOTRUN.IN.TH ',
        }
      },
      {
        path: 'event-adjustment-admin/:q',
        name: 'event_adjustment_admin',
        component: event_adjustment_admin,
        meta: {
          title: 'ADMIN ADJUSTMENT ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที',
        }
      },
      {
        path: 'event-create',
        name: 'event_create',
        component: event_create,
        meta: {
          title: 'CREATE EVENT ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที  ',
        }
      },
      
      {
        path: 'system-dashboard',
        name: 'system_dashboard',
        component: system_dashboard,
        meta: {
          title: 'system dashboard - ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'administrator-dashboard',
        name: 'administrator_dashboard',
        component: administrator_dashboard,
        meta: {
          title: 'administrator dashboard  ',
        }
      },
      {
        path: 'wallet',
        name: 'wallet',
        component: wallet,
        meta: {
          title: 'กระเป๋าเงิน - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },
      {
        path: 'withdrawal-history',
        name: 'withdrawal-history',
        component: withdrawalHistory,
        meta: {
          title: 'ประวิติการถอนเงิน - ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ',
        }
      },

    ]
  },
  {
    path: '/dashboard',
    component: Body,
    children: [
      {
        path: 'default',
        name: 'default',
        component: home,
        meta: {
          title: 'ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
        }
      },
    ]
  },
  {
    path: '/users',
    component: Body,
    children: [
      {
        path: "profile",
        name: "profile",
        component: userProfile,
        meta: {
          title: "Users Profile | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ",
        },
      },
      {
        path: "edit",
        name: "edit",
        component: userEdit,
        meta: {
          title: "Users Edit | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ",
        },
      },
      {
        path: "cards",
        name: "cards",
        component: userCards,
        meta: {
          title: "Users Cards | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ",
        },
      }
    ]
  },
  {
    path: '/error-400',
    name: 'Error400',
    component: Error400,
    meta: {
      title: 'Error400 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/error-401',
    name: 'Error401',
    component: Error401,
    meta: {
      title: 'Error401 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/error-403',
    name: 'Error403',
    component: Error403,
    meta: {
      title: 'Error403 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/error-404',
    name: 'Error404',
    component: Error404,
    meta: {
      title: 'Error404 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/error-500',
    name: 'Error500',
    component: Error500,
    meta: {
      title: 'Error500 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/error-503',
    name: 'Error503',
    component: Error503,
    meta: {
      title: 'Error503 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/login/one',
    name: 'LoginOne',
    component: LoginOne,
    meta: {
      title: 'LoginOne | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/login/two',
    name: 'LoginTwo',
    component: LoginTwo,
    meta: {
      title: 'LoginTwo | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/login/validate',
    name: 'LoginValidate',
    component: LoginValidate,
    meta: {
      title: 'LoginValidate | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/login/tooltip',
    name: 'LoginTooltip',
    component: LoginTooltip,
    meta: {
      title: 'LoginTooltip | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/login/sweetalert',
    name: 'LoginSweetalert',
    component: LoginSweetalert,
    meta: {
      title: 'LoginTooltip | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/register/image',
    name: 'RegisterImage',
    component: RegisterImage,
    meta: {
      title: 'RegisterImage | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/register/image2',
    name: 'RegisterImage2',
    component: RegisterImage2,
    meta: {
      title: 'RegisterImage2 | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/unlockuser',
    name: 'Unlock',
    component: Unlock,
    meta: {
      title: 'Unlock | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: 'ForgetPassword | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'ResetPassword | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/authentication/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      title: 'ResetPassword | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/comingsoon/comingsoon-image',
    name: 'ComingsoonImage',
    component: ComingsoonImage,
    meta: {
      title: 'ComingsoonImage | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
  {
    path: '/comingsoon/comingsoon-simple',
    name: 'ComingsoonSimple',
    component: ComingsoonSimple,
    meta: {
      title: 'ComingsoonSimple | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
    }
  },
    {
    path: '/auth',
    children: [
      {
        path: 'login',
        name: 'Login 1',
        component: login,
        meta: {
          title: 'Photo Management | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
        }
      },
      {
        path: 'forgot-password',
        name: 'Forgot_Password',
        component: Forgot_Password,
        meta: {
          title: 'Forgot_Password | RUNNING.IN.TH ค้นหาใบหน้า ด้วยรอยยิ้ม',
        }
      },
      {
        path: 'register',
        name: 'register 1',
        component: Register,
        meta: {
          title: ' Register | ISHOOTRUN.IN.TH ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ',
        }
      }
    ]
  },
  
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title)
    document.title = to.meta.title;
  const path = ['/auth/login', '/auth/register', '/auth/forgot-password'];
  if (path.includes(to.path) || localStorage.getItem('jwt')) {
    return next();
  }
  next('/auth/login');
});
export default router