<template>
  <Breadcrumbs title="ประวัติการถอนเงิน" main="Pages" />
  <div class="container-fluid">
    <div class="row">
      <p style="text-align: left; font-size: xx-small">
        <ui>
          <li>
            <b>สถานะการถอนเงิน</b>
            <br />&nbsp;&nbsp;&nbsp;&nbsp; > REQUEST ถอนเงิน
            <br />&nbsp;&nbsp;&nbsp;&nbsp; > APPROVE
            ตรวจสอบและส่งบัญชีโอนเงินเรียบร้อย ใช้เวลาประมาณ 1-3 วันทำการ แนะนำให้กดถอนช่วงต้นๆ สัปดาห์ เพื่อไม่ให้ติดช่วงวันหยุดเสาร์-อาทิตย์ของฝ่ายบัญชีธนาคาร<br />&nbsp;&nbsp;&nbsp;&nbsp; >
            COMPLETED
          </li>
        </ui>
      </p>
      <div class="col-sm-12">
        <div class="table-responsive currency-table">
          <table class="table table-striped">
            <thead>
              <tr style="font-weight: bold">
                <th scope="col" style="font-weight: bold">รหัสคำขอ</th>
                <th scope="col" style="font-weight: bold">วันที่ถอนเงิน</th>
                <th scope="col" style="font-weight: bold">ยอดขาย</th>
                <th scope="col" style="font-weight: bold">ค่าธรรมเนียม</th>
                <th scope="col" style="font-weight: bold">ยอดโอน</th>
                <th scope="col" style="font-weight: bold">ธนาคาร</th>
                <th scope="col" style="font-weight: bold">เลขที่บัญชี</th>
                <th scope="col" style="font-weight: bold">สถานะ</th>
                <th scope="col" style="font-weight: bold">ล่าสุด</th>
              </tr>
            </thead>
            <tbody v-if="this.history.length === 0">
              <tr>
                <td colspan="9">
                  <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="this.history.length !== 0">
              <tr v-for="item in this.history" :key="item">
                <td>
                  <b>{{ item.wd_key }}</b>
                </td>
                <td>{{ item.request_date }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.fee }}</td>
                <td>{{ item.transfer_amount }}</td>
                <td>{{ item.bank_name }}</td>
                <td>{{ item.bank_account_no }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.transfer_date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      history: [],
    };
  },
  async mounted() {
    try {
      this.is_loading = "Y";
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      const response = await axios.post("/v1/withdrawal/history", null, {
        headers
      });

      if (response.data.status === "success") {
        this.history = response.data.body;
      }
    } catch (error) {
      alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ", error);
      //this.$router.push({ path: "/auth/login" });
    }
  },
};
</script>
