<template>
  <Breadcrumbs title="Edit Profile" main="Users" />
  <div class="container-fluid">
    <div class="edit-profile">
      <div class="row">
        <!-- <sidePage /> -->
        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mb-0">ช่างภาพ</h4>
              <div class="card-options">
                <a
                  class="card-options-collapse"
                  href="javascript:void(0)"
                  data-bs-toggle="card-collapse"
                  ><i class="fe fe-chevron-up"></i></a
                ><a
                  class="card-options-remove"
                  href="javascript:void(0)"
                  data-bs-toggle="card-remove"
                  ><i class="fe fe-x"></i
                ></a>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="updatePassword">
                <div class="row mb-2">
                  <div class="profile-title">
                    <div class="media">
                      
                      <img width="100" 
                      v-if="this.profile_image_url === null || this.profile_image_url==='' || this.profile_image_url==='null'"
                        class="img-70 rounded-circle"
                        src="@/assets/images/running/running-logo-s.png"
                        alt="profile"
                      />
                      <img  v-else
                        class="img-70 rounded-circle"
                        alt="profile"
                        :src="this.profile_image_url"
                        
                      />


                      
                      <div class="media-body">
                        <h5 class="mb-1" v-if="this.user">
                          {{ this.name }}
                          {{ this.sername }}
                        </h5>
                        <p v-if="this.role_name">
                          {{ this.role_name }}
                        </p>
                        <p v-if="!this.role_name">photographer</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="mb-3">
                  <label class="form-label" for="customFile"
                    >รูปภาพโปรไฟล์</label
                  >
                  <input type="file" class="form-control" id="customFile" />
                </div> -->
                <div class="mb-3">
                  <label class="form-label">หมายเลขช่างภาพ</label>
                  <input class="form-control" v-model="this.user_id_text" disabled />
                </div>
                <div class="mb-3">
                  <label class="form-label">Email-Address</label>
                  <input
                    class="form-control"
                    v-model="this.email"
                    disabled="your-email@domain.com"
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label">รหัสผ่าน</label>
                  <input
                    class="form-control"
                    v-model="this.password"
                    type="password"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">ยืนยันรหัสผ่าน</label>
                  <input
                    class="form-control"
                    v-model="this.confirm_password"
                    type="password"
                  />
                </div>
                <div class="form-footer">
                  <button type="submit" class="btn btn-primary btn-block">
                    อัปเดตรหัสผ่าน
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- <editProfile /> -->
        <div class="col-xl-8">
          <form class="card" @submit.prevent="updateProfile">
            <div class="card-header">
              <h4 class="card-title mb-0">แก้ไขข้อมูลช่างภาพ</h4>
              <div class="card-options">
                <a
                  class="card-options-collapse"
                  href="javascript:void(0)"
                  data-bs-toggle="card-collapse"
                  ><i class="fe fe-chevron-up"></i></a
                ><a
                  class="card-options-remove"
                  href="javascript:void(0)"
                  data-bs-toggle="card-remove"
                  ><i class="fe fe-x"></i
                ></a>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div class="mb-3">
                    <label class="form-label">ช่างภาพ/ชื่อที่แสดง</label>
                    <input class="form-control" v-model="this.display_name" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3">
                    <label class="form-label">โทรศัพท์</label>
                    <input class="form-control" v-model="this.mobile_no" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="mb-3">
                    <label class="form-label">Line ID</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.line_id"
                    />
                  </div>
                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">ชื่อ</label>
                    <input
                      class="form-control"
                      v-model="this.name"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">นามสกุลผู้</label>
                    <input
                      class="form-control"
                      v-model="this.sername"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">บ้านเลขที่</label>
                    <input
                      class="form-control"
                      v-model="this.address"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3">
                    <label class="form-label">แขวง/ตำบล</label>
                    <input
                      class="form-control"
                      v-model="this.sub_district"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="mb-3">
                    <label class="form-label">เขต/อำเภอ</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.district"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3">
                    <label class="form-label">จังหวัด</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.province"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">รหัสไปรษณีย์</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="this.post_code"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">แฟนเพจ URL</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.fanpage_url"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div>
                    <label class="form-label">เกี่ยวกับฉัน</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="this.about_me"
                      placeholder="เกี่ยวกับฉัน"
                    ></textarea>
                  </div>
                </div>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <div class="col-sm-6 col-md-4">
                  <div class="mb-3">
                    <label class="form-label">ชื่อบัญชี</label>
                    <input
                      class="form-control"
                      v-model="this.bank_account_name"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="mb-3">
                    <label class="form-label">เลขที่บัญชี</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.bank_account_no"
                      placeholder="xxxxxxxxxx"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="mb-3">
                    <label class="form-label">ธนาคาร</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="this.bank_name"
                      placeholder="กสิกรไทย"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer text-end">
              <button class="btn btn-primary" type="submit">
                Update Profile
              </button>

              <!-- <button class="btn btn-secondary" type="reset">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";


export default {
  name: "UserEdit",
  components: {},
  setup() {},
  data() {
    return {
      user: null,
      user_id_text: "",
      user_id: "",
      name: "",
      sername: "",
      role_name: "",
      email: "",
      display_name: null,
      mobile_no: "",
      fanpage_url: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      post_code: "",
      about_me: "",
      bank_account_name: "",
      bank_account_no: "",
      bank_name: "",
      line_id: "",
      profile_image_url: "",
      token: localStorage.getItem("jwt")
    };
  },
  async created() {//todo
    try {
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const responseUser = await axios.post("/v1/user/user",null, {
        headers
      });
      if (responseUser.data.status === "success") {
        this.user = JSON.parse(JSON.stringify(responseUser.data.body));

        this.user_id = this.user.user_id;
        this.user_id_text = 'IS'+this.user.user_id_text;
        
        this.name = this.user.name;
        this.sername = this.user.sername;
        this.role_name = this.user.role_name;

        this.email = this.user.email;
        this.display_name = this.user.display_name;
        this.mobile_no = this.user.mobile_no;
        this.fanpage_url = this.user.fanpage_url;
        this.address = this.user.address;
        this.sub_district = this.user.sub_district;
        this.district = this.user.district;
        this.province = this.user.province;
        this.post_code = this.user.post_code;
        this.about_me = this.user.about_me;
        this.bank_account_name = this.user.bank_account_name;
        this.bank_account_no = this.user.bank_account_no;
        this.bank_name = this.user.bank_name;
        this.line_id = this.user.line_id;
        this.profile_image_url = this.user.profile_image_url;
        
      } else {
        alert("พบข้อผิดพลาดกรุณา login อีกครั้ง");
        //this.$router.push({ path: "/auth/login" });
      }
    } catch (error) {
      //this.$router.push({ path: "/auth/login" });
    }
  },
  methods: {
    async updateProfile() {
      try {
        
        let data = {
          user_id: this.user_id,
          name: this.name,
          sername: this.sername,
          email: this.email,
          display_name: this.display_name,
          mobile_no: this.mobile_no,
          fanpage_url: this.fanpage_url,
          address: this.address,
          sub_district: this.sub_district,
          district: this.district,
          province: this.province,
          post_code: this.post_code,
          about_me: this.about_me,
          bank_account_name: this.bank_account_name,
          bank_account_no: this.bank_account_no,
          bank_name: this.bank_name,
          line_id: this.line_id,
          profile_image_url: this.profile_image_url,
          token: localStorage.getItem("jwt")
        };

        //console.log("data:" + JSON.stringify(data));

        const headers = {
         'Authorization': 'Bearer ' + this.token
      };
        const responseUser = await axios.post("/v1/user/user-update",data, {
          headers
        });

          //console.log("responseUserXX:",responseUser)
        if (responseUser.data.status === "success") {
          alert("บันทึกข้อมูลสำเร็จ");
          location.reload();
        } else {
          alert("[unauthorized] พบข้อผิดพลาด! กรุณา login แล้วลอง update ข้อมูลอีกครั้ง");
          //this.$router.push({ path: "/auth/login" });
        }
      } catch (error) {
        alert("พบข้อผิดพลาด! กรุณา login แล้วลอง update ข้อมูลอีกครั้ง");
        //this.$router.push({ path: "/auth/login" });
      }
    },
    async updatePassword() {
      try {

        if(this.password !== this.confirm_password){
          alert("password ไม่ตรงกัน!")

        }else if(this.password.length <8){
          alert("รหัสผ่านควรมีความยาว 8 ตัวอักษรขึ้นไป")
        }else{
          if(confirm("ยื่นยัน การตั้งรหัสเข้าใช้งานใหม่?")){
            let data = {
              password: this.password
            };
           // console.log("data:" + JSON.stringify(data));

           const headers = {
            'Authorization': 'Bearer ' + this.token
          };
            const responseUser = await axios.post("/v1/user/user-password-update",data, {
              headers
            });

            if (responseUser.data.status === "success") {
              alert("บันทึกข้อมูลสำเร็จ");
              //this.$router.push({ path: "/auth/login" });
              
            } else {
              alert("[unauthorized] พบข้อผิดพลาด! กรุณา login แล้วลอง update ข้อมูลอีกครั้ง");
              //this.$router.push({ path: "/auth/login" });
            } 
          }else{
            return false;
          }

        }
      } catch (error) {
        alert("พบข้อผิดพลาด! กรุณา login แล้วลอง update ข้อมูลอีกครั้ง");
        //this.$router.push({ path: "/auth/login" });
      }
    },
  },
  mounted() {},
};
</script>