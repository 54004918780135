<template>
  <div class="col-xxl-12">
    <div class="row">
      <div class="card social-profile">
        <div class="card-body">
          <div class="social-img-wrap">
            <div class="social-img">
              <img 
                src="@/assets/images/running/running-logo-s.png"
                alt="profile"
              />
            </div>

            <div class="edit-icon">
              <svg>
                <use href="@/assets/svg/icon-sprite.svg#profile-check"></use>
              </svg>
            </div>
          </div>
          <div class="social-details">
            <h5 class="mb-1">
              <!-- <router-link to="/app/socialPage">Brooklyn Simmons</router-link> -->
              <b v-if="this.user">{{ this.user.name }} {{ this.user.sername }}</b>
              <b v-if="!this.user"> <vue-feather type="loader" size="20" animation="spin" ></vue-feather>กำลังโหลดข้อมูล...</b>
            </h5>
            <span class="f-light"
              >http:///www.ishootrun.in.th/@IS{{ this.user.user_id_text }}</span
            >
            <div class="row">
              <div class="col-xl-4 col-sm-12">
                <b>Photographer No: IS{{ this.user.user_id_text }}</b>
              </div>
              <div class="col-xl-4 col-sm-12">
                <b>Name: {{ this.user.display_name }}</b>
              </div>
              <div class="col-xl-4 col-sm-12">
                <b>Email: {{ this.user.email }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeCard",
  data() {
    return {
      user: {},     
      loading: true,
    };
  },
  async mounted() {
    try {
      this.user = JSON.parse(localStorage.getItem('user'))
    } catch (error) {
      //this.$router.push({ path: "/auth/login" });

    }
  },
  computed: {
    //...mapGetters(['user'])
  },
};
</script>
