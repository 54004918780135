<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <b class="m-0">คำสั่งซื้อ: {{ this.$route.params.confirm_key }}
                <br>จำนวน: {{ this.length }} ใบ
                <br>วันที่ชำระเงิน: {{ this.payment_date }}
                <br>รูปจะแสดงเรียงตามรหัสของช่างภาพ และชื่อรูปภาพ</b><br>
                <br>
            </div>
          </div>
          <div class="card-body">
            <p v-if="this.orders===null"><vue-feather type="clock" size="14"></vue-feather> หมดเวลาจัดเก็บข้อมูลรูปภาพ</p>
          <div class="container-fluid" v-if="this.loadding ==='N'">
                <figure v-for="(src, index) in orders_sort" :key="index">
                  <div class="gallery gellerycont">
                   
                     <!-- <img 
                      :src="this.s3_url+this.event_key+'/thumbnail/'+src.thumbnail_file_name"
                      :alt="src.original_file_name"
                      loading="lazy"
                    /> -->
                    <!--<img v-if="this.order_id <= 5000"
                      :src="src.thumbnail_file_name"
                      :alt="src.original_file_name"
                      loading="lazy"
                    /> -->
                    <img 
                      :src="this.s3_url+src.image_thumbnail"
                      :alt="src.original_file_name"
                      loading="lazy"
                    />

                    <label
                      class="btn"
                      :for="src.id"
                      >IS{{src.photographer_id}}<br>{{src.original_file_name.replace(' ','')}}</label
                    >
                  </div>
                </figure>
              </div>
          </div>
          
          <div class="d-flex justify-content-center" v-if="this.loadding === 'Y'" >
              <img  style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
          </div>

          <div v-if="this.length === 0 && this.loadding === 'N'">
            <div class="d-flex justify-content-center">
              this page is not authorized
            </div>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>

import VuejsPaginateNext from "../../components/Paginate.vue";
import axios from "axios";
export default {
  name: "order",
  components: {
    paginate: VuejsPaginateNext,
  },
  setup() {},
  data() {
    return {
      orders: [],
      length: 0,
      payment_date: null,
      images_paid: [],
      page_tab: 0,
      loadding: "N",
      page: 1,
      init_page_size: 7,
      s3_url: 'https://ishootrun-clound.s3.ap-southeast-1.amazonaws.com/',
      //s3_url: process.env.VUE_APP_AWS_S3_URL,
      event_key: this.$route.params.q,
      order_id: this.$route.params.confirm_key.substring(5),
      token: localStorage.getItem("jwt")

    };
  },
  computed: {
    
    orders_sort() {
      if(this.orders !== null){
          return this.orders
          .sort((a,b)=> (a.photographer_id - b.photographer_id || a.original_file_name.localeCompare(b.original_file_name)  ));
        }
      
    },
  },
  async mounted() {
    
    try {
      this.loadding = "Y";
      let data = {
        confirm_key: this.$route.params.confirm_key
      }
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "/v1/order/order-paid-view-cart/" + this.$route.params.q,data,
        {
          headers
        }
      );

      //console.log("response:"+JSON.stringify(response.data))
      if (response.data.status === "success") {
        this.orders = JSON.parse(response.data.body);
        this.length = response.data.length;
        this.payment_date = response.data.payment_date;
        //this.images_paid = this.orders.images_paid
        //this.orders = JSON.parse(response.data.body.images_paid)
        
      }
      this.loadding = "N";
      
    } catch (error) {
      alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ", error);
      //this.$router.push({ path: "/auth/login" });
      this.loadding = "N";
    }
  },
  methods: {
    
  },
};
</script>

<style scoped>
.align-to-right {
  float: right;
  padding: 10px;
  background-color: hotpink;
}

input[type="checkbox"] {
  display: none;
}

div.gellerycont:hover {
  cursor: pointer;
  opacity: 0.9;
}
@media screen and (min-width: 451px) and (max-width: 9000px) {
  div.gellerycont {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
    
  }

  div.gellerycont img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  div.gellerycont {
    width: 49%;
    float: left;
    margin: 1px;
    padding: 2px;
    border: 1px solid #ccc;
  }

  div.gellerycont img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }
}

.gellerycont {
  position: relative;
  width: auto;
  height: auto;
}

.gellerycont img {
  height: auto;
  width: auto;
}

.gellerycont .btn {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #420a0a00;
  color: white;
  font-size: 10px;
  border-radius: 5px;
}


</style>
